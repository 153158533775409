<template>
    <div class="activity-info">
        <p v-if="transaction.withdrawal && Object.entries(transaction.withdrawal).length" class="mb-1">
            <span class="fw-bolder">Withdrawal</span>
            <br />
            <span class="fs-6">
                {{ transaction.withdrawal.bank || transaction.withdrawal.method }}
                - {{ transaction.withdrawal.name }}
            </span>
        </p>
        <p v-else-if="transaction.withdrawal_id" class="mb-1 fw-bolder">
            Withdrawal
        </p>
        <p v-if="transaction.receiver &&  transaction.payer && Object.entries(transaction.receiver).length && Object.entries(transaction.payer).length" class="mb-1 fw-bolder">
            Transference
        </p>
        <p v-if="transaction.order" class="mb-1 fw-bolder">
            Order
        </p>
        <p v-if="transaction.chargeback_id" class="mb-1 fw-bolder">
            Chargeback
        </p>
        <p v-if="transaction.reason" class="activity-info__reason mb-0">
            {{ transaction.reason }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'ActivityInfo',
    props: {
        transaction: {
            type: Object,
            default: null,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.activity-info {
    &__reason {
        &::first-letter {
            text-transform: capitalize;
        }
    }
}
</style>
