<template>
    <span :class="{
        'text-red': type === 'charge',
        'text-green': type === 'payment'
    }">{{ type === 'payment' ? '+' : '-' }}{{ amountFormatted }}<small class="fs-12px">{{ currency }}</small></span>
</template>

<script>
export default {
    props: {
        type: { type: String, default: null },
        amount: { type: Number, default: 0 },
        currency: { type: String, default: 'USD' },
    },
    data() {
        return {
            amountFormatted: this._currency(this.amount, this.currency, false),
        }
    },
}
</script>