<template>
    <div class="payout-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="row g-2 mb-4">
                <div class="col-md-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search payouts"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <template v-if="_available_filters">
                    <div class="col-md-2">
                        <ui-select-multiple
                            id="type"
                            name="type"
                            label="Type"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            v-model:value="filters.type"
                            :options="_available_filters.types"
                        />
                    </div>
                    <div class="col-md-2">
                        <ui-select-multiple
                            id="status"
                            name="status"
                            label="Status"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            v-model:value="filters.status"
                            :options="_available_filters.status"
                        />
                    </div>
                    <div class="col-md-2">
                        <ui-select-multiple
                            id="currency"
                            name="currency"
                            label="Currency"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            v-model:value="filters.currency"
                            :options="_available_filters.currencies.map(currency => ({ key: currency, value: currency }))"
                        />
                    </div>
                </template>
            </div>
            <ui-table class="mb-4" :items="payouts" :fields="fields" :busy="busy">
                <template #cell(id)="data">
                    <ui-link :to="{ name: 'transactions-info', query: { id: data.item.search_id } }">
                        {{ data.item.transaction_id }}
                    </ui-link>
                    <span v-if="data.item.reference" class="d-block fs-13px word-break">
                        <strong>Concept:</strong> <br />
                        {{ data.item.concept }}
                    </span>
                    <span v-if="data.item.reference_id" class="d-block fs-13px word-break">
                        <strong>Reference Id:</strong> <br />
                        {{ data.item.reference_id }}
                    </span>
                </template>
                <template #cell(account)="data">
                    <span v-if="data.item.receiver">
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.receiver.id } }" target="_blank">
                            <span class="font-weight-600">{{ data.item.receiver.first_name }} {{ data.item.receiver.last_name || '' }}</span>
                        </ui-link>
                        <div>
                            <ui-link v-if="data.item.receiver.email" underline weight="regular" :link="'mailto:' + data.item.receiver.email">
                                {{ data.item.receiver.email }}
                            </ui-link>
                            <ui-link v-else-if="data.item.receiver.phone" underline weight="regular">
                                {{ data.item.receiver.phone }}
                            </ui-link>
                        </div>
                        <ui-badge v-if="data.item.receiver.name" variant="success" class="align-self-start badge-large">{{ data.item.receiver.name }}</ui-badge>
                    </span>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(payer)="data">
                    <template v-if="data.item.payer">
                        <ui-link v-if="data.item.payer.id" weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.payer.id } }" target="_blank">
                            <span class="font-weight-600">{{ data.item.payer.first_name }} {{ data.item.payer.last_name || '' }}</span>
                        </ui-link>
                        <span v-else>
                            <span v-b-tooltip="'The account is not registered.'" class="fas fa-question-circle text-gray-500"></span>
                            {{ data.item.payer.first_name }} {{ data.item.payer.last_name || '' }}
                        </span>
                        <div>
                            <ui-link v-if="data.item.payer.email" underline weight="regular" :link="'mailto:' + data.item.payer.email">
                                <span>{{ data.item.payer.email }}</span>
                            </ui-link>
                            <ui-link v-else-if="data.item.payer.phone" underline weight="regular">
                                {{ data.item.payer.phone }}
                            </ui-link>
                        </div>
                    </template>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(activity)="data">
                    <activity-info :transaction="data.item"></activity-info>
                </template>
                <template #cell(gateway)="data">
                    <span class="text-uppercase">{{ data.item.gateway }}</span>
                </template>
                <template #cell(amount)="data">
                    <ui-transaction-amount :amount="data.item.amount" :currency="data.item.currency" :type="data.item.type"></ui-transaction-amount>
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('L') }} <br />
                    {{ $dayjs(data.item.created_at).format('LT') }}
                </template>
                <template #cell(status)="data">
                    <ui-status class="fs-13px" :status="data.item.status"></ui-status>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
    </div>
</template>

<script>
import ActivityInfo from '@/components/transactions/ActivityInfo.vue';
import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link.vue';
import UiPagination from '@/components/ui/Pagination';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table.vue';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton.vue';
import UiTransactionAmount from '@/components/ui/TransactionAmount.vue';
import UiBadge from '@/components/ui/Badge';

import table_mixin from '@/mixins/tables.mixin';

export default {
    components: {
        ActivityInfo,
        UiInput,
        UiLink,
        UiPagination,
        UiSelectMultiple,
        UiStatus,
        UiTable,
        UiTableSkeleton,
        UiTransactionAmount,
        UiBadge
    },
    mixins: [table_mixin],
    data() {
        return {
            payouts: null,
            filters: {
                q: '',
                page: 1,
                limit: 50,
                type: null,
                currency: null,
                status: null,
            },
            total_pages: null,
            fields: [
                { key: 'id', label: 'Transaction ID' },
                { key: 'account', label: 'Account' },
                { key: 'payer', label: 'Customer' },
                { key: 'activity', label: 'Activity' },
                { key: 'amount', label: 'Amount' },
                { key: 'created_at', label: 'Date' },
                { key: 'status', label: 'Status' }
            ],
            busy: false,
            loading: false,
            inititialLoading: true,
        };
    },

    watch: {
        filters: {
            immediate: false,
            handler() {
                this.getPayouts();
            },
            deep: true,
        },
    },

    mounted() {
        this._getFilters('payouts');
    },

    methods: {
        async getPayouts() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;
                const params = { ...this.filters };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/payouts', { params });
                this.total_pages = data.pages;
                this.payouts = data.docs;
            } catch (error) {
                console.error(error);
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
    },
};
</script>
