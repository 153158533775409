export default {
    methods: {
        setStatusTransaction(status) {
            let statusClass = 'badge--primary';

            if (['paid', 'active', 'accepted', 'verified', 'enabled', 'requested' ].includes(status)) {
                statusClass = 'badge--success';
            }

            if (['in_review'].includes(status)) {
                statusClass = 'badge--pending';
            }

            if (['pending', 'processing', 'onhold', 'unclaimed', 'in process', 'partially_refunded', 'refunded', 'disabled'].includes(status)) {
                statusClass = 'badge--warning';
            }

            if (['cancelled', 'failed', 'blocked', 'block', 'reversed', 'returned', 'declined', 'decline', 'inactive', 'expired', 'fraudulent', 'rejected'].includes(status)) {

                statusClass = 'badge--danger';
            }

            if (['no_information'].includes(status)) {
                statusClass = 'badge--secondary';
            }

            return statusClass;
        },
    },
};
